var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseDetailSimple',{attrs:{"leave-guard":_vm.leaveGuard,"query-values":_vm.queryValues,"entity-type":"group","locale-section":"pages.groups","field-section":"groups"},scopedSlots:_vm._u([{key:"default",fn:function({ draft, changed }){return [_vm._v(" "+_vm._s(_vm.$t("pages.groups.type"))+" "),_c('br'),_c('b-form-radio-group',{attrs:{"id":"form-element-type","options":[
        {
          text: _vm.$t('pages.groups.die'),
          value: 'die'
        },
        {
          text: _vm.$t('pages.groups.monument'),
          value: 'monument'
        },
        {
          text: _vm.$t('pages.groups.carver'),
          value: 'carver'
        }
      ],"button-variant":"outline-primary","buttons":""},on:{"change":changed},model:{value:(draft.type),callback:function ($$v) {_vm.$set(draft, "type", $$v)},expression:"draft.type"}}),_c('hr'),_vm._v(" "+_vm._s(_vm.$t("pages.groups.notes"))+" "),_c('b-row',[_c('b-col',[_c('b-form-input',{attrs:{"id":"form-element-notes"},on:{"change":changed,"input":changed},model:{value:(draft.notes),callback:function ($$v) {_vm.$set(draft, "notes", $$v)},expression:"draft.notes"}})],1),_c('b-col',{attrs:{"sm":"auto"}},[(_vm.languages.length > 0 && draft.lang)?_c('multiselect',{attrs:{"options":_vm.languages.map(i => i.id),"custom-label":key => _vm.languages.find(x => x.id == key).sv,"show-labels":false},on:{"select":changed},model:{value:(draft.lang.id),callback:function ($$v) {_vm.$set(draft.lang, "id", $$v)},expression:"draft.lang.id"}}):_vm._e()],1)],1),_c('hr'),_c('label',{attrs:{"for":"form-element-inscriptions"}},[_vm._v(" "+_vm._s(_vm.$t("pages.groups.inscriptions"))+" ")]),(draft.inscriptions)?_c('inscriptions-selector',{attrs:{"id":"form-element-inscriptions"},on:{"change":changed},model:{value:(draft.inscriptions),callback:function ($$v) {_vm.$set(draft, "inscriptions", $$v)},expression:"draft.inscriptions"}}):_vm._e(),_c('hr'),_c('label',{attrs:{"for":"form-element-objects"}},[_vm._v(" "+_vm._s(_vm.$t("pages.groups.objects"))+" ")]),(draft.objects)?_c('physical-objects-selector',{attrs:{"id":"form-element-objects"},on:{"change":changed},model:{value:(draft.objects),callback:function ($$v) {_vm.$set(draft, "objects", $$v)},expression:"draft.objects"}}):_vm._e(),_c('hr'),_vm._v(" "+_vm._s(_vm.$t("pages.sources.header"))+" "),(draft.sources)?_c('sources-selector',{attrs:{"id":"'form-element-sources"},on:{"change":changed},model:{value:(draft.sources),callback:function ($$v) {_vm.$set(draft, "sources", $$v)},expression:"draft.sources"}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }