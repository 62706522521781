<template>
  <BaseDetailSimple
    :leave-guard="leaveGuard"
    :query-values="queryValues"
    entity-type="group"
    locale-section="pages.groups"
    field-section="groups"
  >
    <template v-slot:default="{ draft, changed }">
      {{ $t("pages.groups.type") }}
      <br />
      <b-form-radio-group
        id="form-element-type"
        v-model="draft.type"
        :options="[
          {
            text: $t('pages.groups.die'),
            value: 'die'
          },
          {
            text: $t('pages.groups.monument'),
            value: 'monument'
          },
          {
            text: $t('pages.groups.carver'),
            value: 'carver'
          }
        ]"
        button-variant="outline-primary"
        buttons
        @change="changed"
      />
      <hr />
      {{ $t("pages.groups.notes") }}
      <b-row>
        <b-col>
          <b-form-input
            id="form-element-notes"
            v-model="draft.notes"
            @change="changed"
            @input="changed"
          />
        </b-col>
        <b-col sm="auto">
          <multiselect
            v-if="languages.length > 0 && draft.lang"
            v-model="draft.lang.id"
            :options="languages.map(i => i.id)"
            :custom-label="key => languages.find(x => x.id == key).sv"
            :show-labels="false"
            @select="changed"
          />
        </b-col>
      </b-row>
      <hr />
      <label for="form-element-inscriptions">
        {{ $t("pages.groups.inscriptions") }}
      </label>
      <inscriptions-selector
        v-if="draft.inscriptions"
        id="form-element-inscriptions"
        v-model="draft.inscriptions"
        @change="changed"
      />
      <hr />
      <label for="form-element-objects">
        {{ $t("pages.groups.objects") }}
      </label>
      <physical-objects-selector
        v-if="draft.objects"
        id="form-element-objects"
        v-model="draft.objects"
        @change="changed"
      />
      <hr />
      {{ $t("pages.sources.header") }}
      <sources-selector
        v-if="draft.sources"
        id="'form-element-sources"
        v-model="draft.sources"
        @change="changed"
      />
    </template>
  </BaseDetailSimple>
</template>

<script>
import BaseDetailPage from "@/components/BaseDetailPage";
import gql from "graphql-tag";

export default {
  name: "GroupDetailPage",
  components: {
    InscriptionsSelector: () => import("@/components/InscriptionsSelector"),
    PhysicalObjectsSelector: () =>
      import("@/components/PhysicalObjectsSelector"),
    SourcesSelector: () => import("@/components/SourcesSelector")
  },
  extends: BaseDetailPage,
  data() {
    return {
      queryValues: /* GraphQL */ `
        type
        notes
        lang {
          id: language
        }
        objects {
          id
        }
        inscriptions {
          id
        }
        sources {
          id
        }
      `,
      languages: []
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    }
  },
  apollo: {
    languages() {
      return {
        query: gql`
          query languages {
            languages: modernLanguages {
              id: language
              sv
            }
          }
        `,
        result(result) {
          if (result.stale) {
            // result is called again with stale = true
            return;
          }

          this.languages = result.data.languages.sort((a, b) =>
            a.sv.localeCompare(b.sv, "sv")
          );
        }
      };
    }
  }
};
</script>
